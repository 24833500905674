<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-07 00:01:45
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-14 18:32:43
 * @Description: 请输入文件描述信息
 * @FilePath: \src\views\Workbench\Workbench.vue
-->
<template>
  <div class="">
    <van-nav-bar
      title="工作台"
      left-arrow
      :fixed="true"
      :placeholder="true"
      :z-index="500"
      :safe-area-inset-top="true"
      @click-left="router.go(-1)"
    />

    <div class="grgox" v-for="item in menuConfig" :key="item.groupId">
      <h2 class="grid-title">{{ item.groupName }}</h2>
      <van-grid square>
        <van-grid-item
          v-for="sitem in item.item"
          :key="sitem.id"
          :icon="sitem.icon"
          :text="sitem.name"
          :to="sitem.path"
        />
      </van-grid>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuConfig: [
        {
          groupId: "rf",
          groupName: "RF作业",
          item: [
            {
              id: "rf-rk",
              icon: require("@/assets/ico/a4wf89a1a6.png"),
              name: "入库扫描",
              path: "/EntryManage",
            },
            {
              id: "rf-sj",
              icon: require("@/assets/ico/o486e.png"),
              name: "上架扫描",
              path: "/ToShelves",
            },
            {
              id: "rf-ck",
              icon: require("@/assets/ico/n1r668q.png"),
              name: "出库扫描",
              path: "/Deliver",
            },
            {
              id: "rf-fj",
              icon: require("@/assets/ico/q9u7cs3nrti8.png"),
              name: "发件扫描",
              path: "/",
            },
            {
              id: "rf-zy",
              icon: require("@/assets/ico/4q864r41h61fdg6.png"),
              name: "库位转移",
              path: "/MoveExpressStorage",
            },
          ],
        },
        {
          groupId: "kcgl",
          groupName: "库存管理",
          item: [
            {
              id: "rf-init",
              icon: require("@/assets/ico/6qw4f96q1f6sdf.png"),
              name: "库位初始化",
              path: "/InitStorage",
            },
            {
              id: "rf-init2",
              icon: require("@/assets/ico/7q98wt79etukjm.png"),
              name: "修改库位信息",
              path: "/ModifyStorage",
            },
            {
              id: "rf-kc",
              icon: require("@/assets/ico/o65e16mu3.png"),
              name: "快件信息查询",
              path: "/QueryExpressInfo",
            },
            {
              id: "rf-cj",
              icon: require("@/assets/ico/89q79871bq3hi.png"),
              name: "拆件验货",
              path: "/Inspect",
            },
            {
              id: "rf-db",
              icon: require("@/assets/ico/56p456q3.png"),
              name: "打包管理",
              path: "/Pack",
            },
            {
              id: "rf-fg",
              icon: require("@/assets/ico/q31mh8d36wq3.png"),
              name: "快件拆分",
              path: "/",
            },
          ],
        },
        {
          groupId: "khgl",
          groupName: "客户管理",
          item: [
            {
              id: "xkh",
              icon: require("@/assets/ico/wa6g1hn68r496e.png"),
              name: "新客户录入",
              path: "/InputNewCustomer",
            },
            {
              id: "khxx",
              icon: require("@/assets/ico/4wa65f131nt9q.png"),
              name: "客户信息查询",
              path: "/",
            },
          ],
        },
        {
          groupId: "ddgl",
          groupName: "订单管理",
          item: [
            {
              id: "cjdd",
              icon: require("@/assets/ico/w7a896v1ws8c.png"),
              name: "创建订单",
              path: "/",
            },
            {
              id: "ddcx",
              icon: require("@/assets/ico/4w6a5f1w6vwa8q9.png"),
              name: "订单管理",
              path: "/",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
.grgox {
  width: 100%;
}

.grid-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0;
  padding-left: 2rem;
  color: #909ca4;
  font-size: 16px;
  font-weight: 400;
}
</style>
